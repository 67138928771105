<template>
  <div class="app-container">
    <SearchDate :query="query" />
    <h2 class="h2">推广统计</h2>

    <van-cell-group style="margin-bottom: 20px">
      <template v-for="(item, index) in dataList">
        <van-cell
          is-link
          :key="index"
          :title="item.realname"
          center
          @click="showDetail(item)"
        >
          <template #title>
            <van-image
              class="wechat-avatar"
              round
              width="12"
              height="12"
              :src="item.wechat_avatar"
            />
            <span class="custom-title">{{ item.realname }}</span>
          </template>

          <div class="items">
            <div class="item">转发量: {{ item.forward_count }}</div>
            <div class="item">下线用户量: {{ item.under_count }}</div>
          </div>
        </van-cell>
      </template>
    </van-cell-group>

    <van-cell-group style="margin-bottom: 20px; padding: 10px 0; height: 365px">
      <VeHistogram :data="dataChart1" />
    </van-cell-group>

    <van-cell-group style="padding: 10px 0; height: 365px">
      <VeHistogram :data="dataChart2" />
    </van-cell-group>

    <Detail ref="detail" />

    <Tabbar :json="{ active: 1 }" />
  </div>
</template>
<script>
import Detail from './components/detail'
import SearchDate from '@/components/common/searchDate'
import Tabbar from '@/components/common/tabbar.vue'
import VeHistogram from 'v-charts/lib/histogram.common'
import { Notify } from 'vant'
export default {
  components: {
    SearchDate,
    Tabbar,
    VeHistogram,
    Detail
  },
  data () {
    return {
      loading: false,
      finished: false,
      dataList: [],
      dataChart1: {},
      dataChart2: {},
      total: 0,
      query: {

      },
      detail: {},
      show: false
    }
  },
  methods: {
    // onLoad () {
    //   setTimeout(() => {
    //     this.getDataList()
    //     this.query.page++
    //   }, 500)
    // },
    getDataList () {
      this.getDataChart1()
      this.getDataChart2()
      this.$axios.post('/statistic_article/data2', this.query).then(res => {
        if (res.code === 200) {
          this.dataList = res.data.list
        }
      })
    },
    getDataChart1 () {
      this.$axios.post('/statistic_article/chart1', this.query).then(res => {
        if (res.code === 200) {
          this.dataChart1 = res.data.list
        }
      })
    },
    getDataChart2 () {
      this.$axios.post('/statistic_article/chart2', this.query).then(res => {
        if (res.code === 200) {
          this.dataChart2 = res.data.list
        }
      })
    },
    showDetail (item) {
      if(item.forward_count === 0){
        Notify({ type: 'danger', message: '没有转发记录' })
        return
      }
      let query = { 
        create_date_begin: this.query.begin_date,
        create_date_end: this.query.end_date,
        client_id: item.client_id,
        count: 1000
      }
      this.$refs.detail.show(query, item)
    }
  }
}
</script>
<style lang="scss" scoped>
.h2 {
  font-size: 18px;
  padding: 10px 0;
}
.wechat-avatar {
  margin-right: 5px;
}
.items {
  font-size: 12px;
}
</style>